@media only screen and (max-width: 1200px) {

    .statistics_row .inrInfoBx img {
        max-width: 70px;
    }

    .statistics_row .inrInfoBx p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 992px) {

    .container,
    .container-sm {
        max-width: calc(100% - 20px);
    }

    .topInformation h1 {
        font-size: 32px;
    }

    .informationMU .infotxt h3 {
        font-size: 32px;
    }

    .banner-area h1 {
        font-size: 24px;
    }

    .info-group p {
        font-size: 16px !important;
    }

    .color-changing-box {
        max-width: 500px;
        margin: 0px auto 50px;
        mix-blend-mode: difference;
    }

    .statistics_row .col4 {
        flex-basis: calc(50% - 15px);
        max-width: calc(50% - 15px);

        &.icons {
            display: none;
        }
    }
}

@media only screen and (max-width: 767px) {
    .banner-area {
        padding: 50px 0 !important;
        position: relative;
    }

    .informationMU .infotxt {
        text-align: justify;
    }

    .informationMU .infotxt p {
        font-size: 13px;
    }

    .countDownMu p {
        font-size: 14px;
    }

    .info-group {
        text-align: center;
        // display: flex;
        justify-content: center;
        align-items: center;
    }

    .bannerbtn {
        margin: 0;
        justify-content: center;
    }

    .nftBalance .balanceViewBx {
        padding-bottom: 130px;
    }

}




@media only screen and (max-width: 575px) {
    .kave-btn {
        width: 150px;
    }


    .topInformation h1 {
        font-size: 22px;
    }

    .informationMU .infotxt h3 {
        font-size: 22px;
    }

    .topInformation .btnMU,
    .topInformation .btn-btnMU {
        font-size: 16px;
        min-width: 150px;
    }

    .nftBalance .balanceViewBx {
        padding: 20px 20px 130px;
    }

    .nftBalance .balanceViewBx ul li {
        margin-bottom: 15px;
        font-size: 14px;
    }

    // .nftBalance .balanceViewBx {
    //     height: 170px;
    // }

    .nftBalance .balanceViewBx .bgbx {
        border-radius: 10px;
    }

    .nftBalance .balanceViewBx ul {
        padding: 0px 10px;
    }

    main section {
        margin-bottom: 30px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 14px;
    }

    footer .copyright span {
        display: block;
        margin-top: 4px;
    }

    footer .footer {
        padding: 35px 0px 15px;
    }

    .connectWalletToggle .modal-content .modal-body {
        padding: 0px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 20px 0px;
    }

    header .header-brand img {
        max-width: 80px;
    }

    .topInformation h1 .lstwld {
        display: block;
    }

    .topInformation {
        margin-bottom: 20px;
    }

    .countDownMu {
        margin: 20px 0px 10px;
        font-size: 22px;

        p {
            font-size: 13px;

            span {
                display: block;
            }
        }
    }

    footer .social-icons a {
        width: 40px;
        height: 40px;
        margin: 0px 5px !important;
    }

    .statistics_row .col4 {
        flex-basis: calc(100%);
        max-width: calc(100%);
    }

    .nftBalance .balanceViewBx:before {
        height: 230px;
        bottom: -140px;
        width: 230px;
    }

    .nftBalance .balanceViewBx:after {
        height: 140px;
        bottom: -95px;
        width: 140px;
    }
}

@media only screen and (max-width: 390px) {
    .nftBalance .balanceViewBx ul li {
        font-size: 12px;
    }

    .topInformation .btnMU,
    .topInformation .btn-btnMU {
        min-width: 105px;
    }

    .header {

        .btnMU,
        .btn-btnMU {
            font-size: 16px;
        }
    }

    .listViewBx .inrInfoBx img {
        max-width: 70px;
    }

    .connectWalletToggle .popupbtngrp button img,
    .connectWalletToggle .popupbtngrp a img {
        height: 40px;
        width: 40px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 12px;
        padding: 10px 4px 10px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 15px 10px;
    }

    .connectWalletToggle .modal-content .modal-title {
        font-size: 18px;
    }

    header .header-brand img {
        max-width: 80px;
    }

    .header {

        .btnMU span,
        .btn-btnMU span {
            display: block;
            padding: 5px 6px;
            font-size: 14px;
        }
    }

    main {
        padding: 15px 0px;
    }

    .banner-area h1 {
        font-size: 22px;
    }

    .info-group p {
        font-size: 16px !important;
    }
}


@media (max-width: 768px) {
    a.underline {
        margin-right: 10px;
        white-space: nowrap;
        margin-left: 0;
    }

    th,
    td {
        font-size: 14px;
        padding: 8px 10px;
    }

    .custom-table {
        width: 100%;
    }
}