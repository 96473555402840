@font-face {
  font-family: 'techmont-Regular';
  src: url('../font/techmont-Regular.eot');
  src: url('../font/techmont-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/techmont-Regular.woff2') format('woff2'),
    url('../font/techmont-Regular.woff') format('woff'),
    url('../font/techmont-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "techmont-Bold1";
  src: url('../font/techmont-Bold1.eot');
  src: url('../font/techmont-Bold1.eot?#iefix') format('embedded-opentype'),
    url('../font/techmont-Bold1.woff2') format('woff2'),
    url('../font/techmont-Bold1.woff') format('woff'),
    url('../font/techmont-Bold1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'techmont-Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: white;
  text-align: left;
  background-color: #000000;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:before {
    background-attachment: fixed;
    background-color: #187518;
    background-image: url(../images/logo.png);
    background-position: 50%;
    background-repeat: repeat;
    background-size: 4px;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.10;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {

  font-family: "techmont-Bold1";
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 2px;
}

p {
  line-height: 1.1;
}

.btn {
  user-select: none;
  z-index: 10;
}

.btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-sizing: border-box;
  transition: all 0.3s;
}

.kave {
  &-btn {
    position: relative;
    border: solid 1px rgba(0, 214, 252, 0.3);
    background: none;
    color: rgb(112 233 255);
    font-family: "techmont-Regular", sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
    width: 200px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    margin: 10px;

    box-shadow: 1px 1px 8px rgb(0 214 252);
    color: rgba(0, 214, 252, 1);
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);

    &.kave-btn:before {
      left: 0;
      width: 20px;
    }

    &.kave-btn:after {
      right: 0;
      width: 20px;
    }

    .kave-line:before {
      bottom: 0;
    }

    .kave-line:after {
      top: 0;
    }


    // &:hover {
    //   box-shadow: 1px 1px 8px rgb(0 214 252);
    //   color: rgba(0, 214, 252, 1);
    //   text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);

    //   &.kave-btn:before {
    //     left: 0;
    //     width: 20px;
    //   }

    //   &.kave-btn:after {
    //     right: 0;
    //     width: 20px;
    //   }

    //   .kave-line:before {
    //     bottom: 0;
    //   }

    //   .kave-line:after {
    //     top: 0;
    //   }
    // }
  }

  &-line {
    &:before {
      content: "";
      position: absolute;
      bottom: 30%;
      right: 0;
      width: 1px;
      height: 20px;
      transform: translateX(1px);
      background: rgba(0, 214, 252, 1);
      transition: all 0.25s ease;
    }

    &:after {
      content: "";
      position: absolute;
      top: 30%;
      left: 0;
      width: 1px;
      height: 20px;
      transform: translateX(-1px);
      background: rgba(0, 214, 252, 1);
      transition: all 0.25s ease;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

a {
  color: #83c8ff;
  outline: none;

  &:hover {
    color: #ffbebe;
  }
}

#root {
  min-height: 100vh;
}

main {
  padding: 40px 0px;

  section {
    margin-bottom: 80px;

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
}



.infonft {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

// fontanimation ///

.banner-area {
  padding: 100px 0 100px;
  position: relative;
  overflow: hidden;

  h1 {
    font-size: 36px;
  }

  .color-changing-element {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: -1;
    top: 0px;
    left: 0px;
    transform: scale(1);
    transform-origin: center;
    filter: hue-rotate(95.6deg) brightness(0.15);
    mix-blend-mode: lighten;
  }
}

.buttonGroup {
  gap: 20px;

  .tech_btn {
    width: 150px;
    height: 50px;
  }
}

.tech_btn {
  position: relative;
  width: 180px;
  height: 55px;
  display: inline-block;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: transparent;

  i:not(.material-icons) {
    position: absolute;
    inset: -2px;
    display: block;
    border-radius: 50px;
  }

  i:not(.material-icons),
  i:nth-child(2) {
    background: linear-gradient(45deg, #ff323e, transparent, transparent, #7710ff);
  }

  i:nth-child(2) {
    filter: blur(10px);
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: #ffffff;
    letter-spacing: 1.5px;
    // border: 1px solid #040a29;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.65);
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: -50%;
      width: 100%;
      height: 100%;
      // background-color: hsla(0, 93%, 35%, 0.25);
      transform: skew(25deg);
      z-index: -1;
    }
  }

  &.cnctwlthedBtn {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;
  }
}

.mainsec {
  position: relative;
}

.nftsec {
  position: relative;
}

.stake_Statistics {
  position: relative;
  padding-top: 50px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    background-image: url('../images/glow-footer.png');
    width: 100%;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    filter: hue-rotate(55deg);
    opacity: 0.2;
    transform: scaleY(-1);
  }
}

.statistics_row {
  margin-top: 40px;
  padding-bottom: 70px;

  .col4 {
    flex: 0 0 calc(33.3% - 15px);
    max-width: calc(33.33% - 15px);
    position: relative;

    video {
      position: absolute;
      mix-blend-mode: exclusion;
      transform: scale(2);
      transform-origin: top;
      z-index: -1;
      top: 70px;
      left: 0px;
    }

    // &:nth-child(5) {
    //   margin-top: 150px;
    // }
  }

  .inrInfoBx {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: none;
    padding: 20px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    .bgbx {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url('../images/glowbg.svg');
      background-size: cover;
      background-position: bottom;
      background-color: #100505;
      opacity: 0.4;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 1px;
      width: 2px;
      height: 100%;
      background-image: linear-gradient(45deg, transparent 70%, #ff323e, transparent 90%);
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 1px;
      width: 2px;
      height: 100%;
      background-image: linear-gradient(0deg, transparent 0%, #7710ff, transparent 30%);
      z-index: 2;
    }

    span {
      flex: 1 0 0;
      margin-left: 30px;
    }

    p {
      font-size: 18px;
      display: block;
      margin-bottom: 10px;
    }

    strong {
      font-size: 24px;
      display: block;
    }

    img {
      max-width: 100px;
      display: block;
    }
  }
}

.nftmainimg {
  position: relative;
  margin: 0 auto;

  .nftvideo {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin-top: -50px;
  }
}

.ntfsecdata {
  display: flex;
  gap: 25px;


}

.osrow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.row {
  display: flex !important;
  flex-wrap: wrap !important;
}




.button-prev,
.button-next {
  width: 50px;
  height: 50px;
  background-color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.button-prev:hover,
.button-next:hover {
  background-color: #00FFFF;
}

.icon {
  width: 24px;
  height: 24px;

}

.planbutton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

// toggle bar  //


.carousel-indicators {
  margin-bottom: 10px;
  position: absolute;
  bottom: auto;
  z-index: 1;
  margin-top: -50px;
}

.carousel-indicators {
  button::before {
    display: none;
  }
}

.carousel-indicators [data-bs-target] {
  text-indent: 0px;
  color: white;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 100px;
  height: 40px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;

  background-clip: padding-box;
  border: 0;
  opacity: 1;
  transition: opacity .6s ease;
}

.carousel-indicators button.active {
  background-color: transparent;
  background-image: linear-gradient(128deg, #00ffff 0%, #156666 100%);
}

.historysec {
  padding-block: 10px;

  nav {
    justify-content: space-around;
    align-items: center;
    padding-block: 15px;
  }

  table thead tr {
    background-color: transparent;
    overflow: hidden;
    justify-content: space-between;
  }

  table {
    position: relative;
    overflow: scroll;
  }

  table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .page-link {
    background-color: transparent;
    color: #fff;
  }
}

.history-headline {
  font-family: "techmont-Bold1";
  font-weight: 700;

  text-shadow: 2px 2px 5px #ff323e;
  font-size: 45px;
  text-transform: capitalize;
  text-align: center;
  // margin-bottom: 40px;

  // span {
  //   -webkit-text-stroke-width: 1px;
  //   -webkit-text-stroke-color: #fff;
  //   -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
  // }
}

.table-container {
  overflow-x: auto;
  width: 100%;

  table {
    min-width: 600px;
    width: 100%;
    border-collapse: collapse;
  }
}

.table-container th,
.table-container td {
  border: 1px solid #00ffff;

}


.nftBalance {
  margin-bottom: 230px;

  .balanceViewBx {
    padding: 30px 20px;
    height: auto;
    border-radius: 10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-image: url('../images/colorring.png');
      background-position: 50%;
      background-size: 110%;
      background-repeat: no-repeat;
      border-radius: 50%;
      height: 280px;
      left: 50%;
      bottom: -160px;
      transform: translate(-50%, 0%);
      width: 280px;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: #080f08;
      background-image: url('../images/colorring-logo.png');
      background-position: 50%;
      background-size: 110%;
      border-radius: 50%;
      height: 180px;
      left: 50%;
      bottom: -110px;
      transform: translate(-50%, 0%);
      width: 180px;
      z-index: 1;
    }

    .bgbx {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url('../images/glowbg.svg');
      background-size: cover;
      background-position: top;
      // opacity: 0.5;
      transform: scaleY(-1);
      background-color: #370200;
      border-radius: 10px;
      border: 2px solid #9b31ff;
      border-top: none;
    }

    ul {
      padding: 0px 50px;
      margin: 0px;
      list-style: none;

      li {
        margin-bottom: 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-last-child(1) {
          margin-bottom: 0px;
        }

        span {
          text-align: right;
        }

        svg {
          margin-left: 10px;
          width: 22px;
          height: auto;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }
}

.nftmainimg {
  margin: 0 auto;
  max-width: 450px;
  display: flex;
  justify-content: center;
  mix-blend-mode: lighten;
}

.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);

  .modal-dialog {
    max-width: 450px;
  }

  .modal-content {
    color: #ffffff;
    background-color: #0a0a0a;
    box-shadow: 0px 0px 70px -40px #666666;
    padding: 0px 20px;
    border: 1px solid #4a4a4d;

    .modal-header {
      align-items: flex-start;
      border: none;
      padding: 20px 10px;
    }

    .modal-body {
      padding: 10px;
    }

    button.btn-close {
      outline: none;
      box-shadow: none !important;
      opacity: 1;
      filter: invert(1);
    }

    .modal-title {
      font-size: 24px;
      margin-bottom: 0px;
      font-family: "Power Grotesk UltBd";
      color: #ffffff;
      text-shadow: 0.3px 0.3px 0.3px #111;

      p {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        margin: 10px 0px 0px;
        text-shadow: none;
      }
    }
  }

  .popupbtngrp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .btngrp {
      flex-basis: calc(33.33% - 0px);
      max-width: calc(33.33% - 0px);
      margin: 0px 0px 20px;
    }

    button,
    a {
      background-color: #000000;
      border: 1px solid #221d26;
      color: #ffffff;
      font-size: 16px;
      padding: 20px 8px 20px;
      border-radius: 5px;
      letter-spacing: 1px;
      line-height: 1.1;
      display: block;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;

      &:hover {
        border-color: #523664;
      }

      img {
        display: block;
        margin: 0 auto 15px;
        height: 50px;
        width: 50px;
        background-color: rgba(0, 0, 0, 0.169);
        object-fit: contain;
      }

      span {
        display: block;
      }
    }
  }
}

.informationMU {
  .infotitle {
    margin-bottom: 30px;

    h2 {
      font-size: 48px;
    }
  }

  .infoimage {
    img {
      width: 100%;
      margin: 0 auto;
      mix-blend-mode: exclusion;
      filter: brightness(1.3);
      max-width: 420px;

    }
  }

  .infotxt {
    h3 {
      font-size: 42px;
      margin-bottom: 20px;
    }

    p {
      letter-spacing: 2px;
      color: #c9c9c9;
      text-align: justify;

      span {
        color: #ffffff;
        font-weight: 100;
      }
    }
  }
}

.Toastify__progress-bar-theme--light {
  background: #ce86f9;
}

.Toastify__toast {
  color: #000000;
  font-size: 18px;
}

.bgVideoBody {
  -o-object-fit: cover;
  object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.0001;

  &,
  video {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust as needed to center vertically */
}

.loader {
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;

  img {
    max-width: 200px;
  }
}



.countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 28px;
  padding-top: 25px;
  flex-wrap: wrap;

  b {
    display: flex;
    text-align: center;
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 20px;
    margin: 0px 10px;
    border-radius: 6px;
    position: relative;

    span {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: "Power Grotesk UltBd";
    }
  }

  p {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 15px auto 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Power Grotesk UltBd";


  }
}

.bannerbtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-group {
  p {
    line-height: 1.5 !important;
    font-size: 18px !important;
  }
}

.titlebox {
  text-align: center;
  max-width: 500px;
  margin: 0px auto 80px;
  position: relative;

  &.titleboxNft {
    max-width: 600px;
    margin: 0px auto 10px;

    h2 {
      margin-bottom: 10px;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  h2 {
    font-size: 48px;
    margin-bottom: 25px;
    line-height: 1;
    text-shadow: 2px 2px 50px #ff323e;
  }
}


// adminpanel css //



.admin-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-section {

  .tech_btn {
    position: relative;
    width: 180px;
    height: 55px;
    display: inline-block;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: transparent;
    max-width: 90px;
    font-size: 14px;

    i:not(.material-icons) {
      position: absolute;
      inset: -2px;
      display: block;
      border-radius: 50px;
    }

    i:not(.material-icons),
    i:nth-child(2) {
      background: linear-gradient(45deg, #ff323e, transparent, transparent, #7710ff);
    }

    i:nth-child(2) {
      filter: blur(10px);
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      color: #ffffff;
      letter-spacing: 1.5px;
      // border: 1px solid #040a29;
      border-radius: 50px;
      background-color: rgba(0, 0, 0, 0.65);
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: -50%;
        width: 100%;
        height: 100%;
        // background-color: hsla(0, 93%, 35%, 0.25);
        transform: skew(25deg);
        z-index: -1;
      }
    }

    &.cnctwlthedBtn {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      align-items: center;
    }
  }
}

.admin-box {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(9deg, rgba(0, 0, 0, 1) 20%, rgb(14 17 48) 80%);
  border: 2px solid rgba(51, 51, 51, 0.38);
  border-radius: 8px;
  padding: 5px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 1px;
    width: 2px;
    height: 100%;
    background-image: linear-gradient(45deg, transparent 70%, #ff323e, transparent 90%);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 1px;
    width: 2px;
    height: 100%;
    background-image: linear-gradient(0deg, transparent 0%, #7710ff, transparent 30%);
    z-index: 2;
  }

}

a.underline {
  margin-left: 8px;
}

.bg-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}



.form-container {
  position: relative;
  z-index: 1;
  padding: 10px 20px;
}

.form-field {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}


.form-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

.formtext {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;

  label {
    white-space: nowrap;
    margin-right: 10px;
  }
}

// table css //
.table-container {
  width: 100%;
  margin: 70px 0;
  display: flex;
}

.custom-table {
  width: 90%;
  border-collapse: collapse;
  text-align: left;
  font-family: Arial, sans-serif;

  .spacer {
    height: 0;
  }

  .spacer td {
    padding: 7px 15px;
    background-color: transparent;
  }

  th,
  td {
    padding: 12px 15px;
    border: none;
    background-color: rgba(32, 40, 118, 0.5);
  }

  th {
    background-color: rgba(18, 16, 85, 1);
    color: #fff;
    font-weight: bold;
  }


  @media (max-width: 768px) {

    th,
    td {
      font-size: 14px;
      padding: 8px 10px;
    }

    .custom-table {
      width: 100%;
    }
  }
}


.connectwltBtn {
  width: 215px;

  span {
    position: relative;

    button {
      background-color: transparent !important;
      color: #ffffff !important;
      width: 100% !important;
      text-align: center !important;
      height: 100% !important;
    }

    &>.iekbcc0 {
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;

      button {
        width: 100% !important;
        text-align: center !important;
        height: 100% !important;
        justify-content: center;
      }

      div {
        text-wrap: nowrap;
        color: #ffffff !important;
        font-weight: 400;
        border: none;
        background: transparent;
      }
    }
  }
}

.nftBtnMain {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -20px);
  z-index: 9;
}

@media (max-width: 768px) {
  .connectwltBtn span button {
    font-size: 14px !important;
  }

  .connectwltBtn {
    width: 155px;
  }

  .nftBtnMain {
    transform: translate(-50%, -120px);
  }
}

@media (max-width: 1024px) {
  .nftBalance .balanceViewBx .bgbx {
    background-size: 300%;
  }
}